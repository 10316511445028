import React from 'react';
import { useTranslation } from 'react-i18next';
import LineDash from '../../components/LineDash';
import HistoryImage from '../../images/about/history.png';

const Section = ({ title, children }) => {
  const { t } = useTranslation('about');
  const historys = t('history.items', { returnObjects: true });
  return (
    <section id="history">
      <LineDash title={t('history.title')}>
        <div
          style={{ backgroundImage: `url(${HistoryImage})` }}
          className="w-full p-4 h-fit bg-no-repeat bg-cover bg-center"
        >
          <div className="flex flex-col lg:flex-row justify-center max-w-5xl mx-auto my-6 p-4 bg-white/80 rounded-3xl">
            <div className="flex lg:hidden flex-row justify-center items-center w-full">
              <div className="flex m-1 px-4 py-2 w-1/2 rounded-full bg-[#ecdeb2] text-lg text-[#62af89]">
                <p>{t('history.left')}</p>
              </div>
              <div className="flex m-1 px-4 py-2 w-1/2 rounded-full bg-[#ecdeb2] text-lg text-[#6185b0]">
                <p>{t('history.right')}</p>
              </div>
            </div>
            <div
              className="hidden lg:flex items-center justify-center"
              style={{ 'writing-mode': 'vertical-rl' }}
            >
              <svg
                width="20px"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                className="overflow-visible"
              >
                <line
                  x1="50%"
                  y1="10%"
                  x2="50%"
                  y2="90%"
                  stroke="#62af89"
                  stroke-width="2"
                  stroke-dasharray="18 5"
                />
                <line
                  x1="50%"
                  y1="10%"
                  x2="2000%"
                  y2="10%"
                  stroke="#62af89"
                  stroke-width="2"
                  stroke-dasharray="18 5"
                />
              </svg>
              <div className="flex whitespace-nowrap text-lg text-[#62af89]">
                <p>{t('history.left')}</p>
              </div>
              <svg
                width="20px"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                className="overflow-visible"
              >
                <line
                  x1="50%"
                  y1="10%"
                  x2="50%"
                  y2="90%"
                  stroke="#62af89"
                  stroke-width="2"
                  stroke-dasharray="18 5"
                />
                <line
                  x1="50%"
                  y1="90%"
                  x2="2000%"
                  y2="90%"
                  stroke="#62af89"
                  stroke-width="2"
                  stroke-dasharray="18 5"
                />
              </svg>
            </div>
            <div className="flex flex-col items-center justify-center w-full lg:max-w-4xl">
              {historys.map((item) => (
                <div className="flex flex-col items-center justify-center w-full">
                  <div className="flex lg:hidden flex-row justify-center items-center w-full">
                    <div className="flex overflow-visible items-center justify-center m-4 p-0.5 rounded-full border-4 border-[#e1c466]">
                      <div className="p-2 rounded-full border-2 border-[#e1c466]">
                        <div className="flex justify-center items-center w-10 h-10 text-lg">
                          <p className="">{item.year}</p>
                        </div>
                      </div>
                      <div className="absolute">
                        <svg
                          width="20px"
                          height="100%"
                          xmlns="http://www.w3.org/2000/svg"
                          className="overflow-visible"
                        >
                          <line
                            x1="50%"
                            y1="-20%"
                            x2="50%"
                            y2="20%"
                            stroke="#e1c466"
                            stroke-width="2"
                          />
                          <line
                            x1="50%"
                            y1="80%"
                            x2="50%"
                            y2="120%"
                            stroke="#e1c466"
                            stroke-width="2"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-center items-center w-full">
                    <div className="flex-1 m-4 text-base text-[#605d73] text-right">
                      <p>{item.left}</p>
                    </div>
                    <div className="hidden lg:flex items-center justify-center m-4 p-0.5 rounded-full border-4 border-[#e1c466]">
                      <div className="p-2 rounded-full border-2 border-[#e1c466]">
                        <div className="flex justify-center items-center w-10 h-10 text-lg">
                          <p className="">{item.year}</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 m-4 text-base text-[#605d73]">
                      <p>{item.right}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className="hidden lg:flex items-center justify-center"
              style={{ 'writing-mode': 'vertical-rl' }}
            >
              <svg
                width="20px"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                className="overflow-visible"
              >
                <line
                  x1="50%"
                  y1="10%"
                  x2="50%"
                  y2="90%"
                  stroke="#6185b0"
                  stroke-width="2"
                  stroke-dasharray="18 5"
                />
                <line
                  x1="-2000%"
                  y1="10%"
                  x2="50%"
                  y2="10%"
                  stroke="#6185b0"
                  stroke-width="2"
                  stroke-dasharray="18 5"
                />
              </svg>
              <div className="flex whitespace-nowrap text-lg text-[#6185b0]">
                <p>{t('history.right')}</p>
              </div>
              <svg
                width="20px"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                className="overflow-visible"
              >
                <line
                  x1="50%"
                  y1="10%"
                  x2="50%"
                  y2="90%"
                  stroke="#6185b0"
                  stroke-width="2"
                  stroke-dasharray="18 5"
                />
                <line
                  x1="-2000%"
                  y1="90%"
                  x2="50%"
                  y2="90%"
                  stroke="#6185b0"
                  stroke-width="2"
                  stroke-dasharray="18 5"
                />
              </svg>
            </div>
          </div>
        </div>
      </LineDash>
    </section>
  );
};

export default Section;
